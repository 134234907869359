<template>
  <div id="app">
    <router-view />

    <b-modal ref="policy" v-model="show" :title="$t('log_in.forgot_alt')" hide-header no-close-on-esc
      no-close-on-backdrop size="lg" :modal-class="'fraktal-modal'">
      <br>
      <h2>{{ policyStatus?.title }}</h2>
      <div class="policy" v-html="policyStatus?.content" />
      <br>
      {{ $moment(policyStatus?.start_date).format('DD/MM/yyyy HH:mm:ss') }}
      <hr>
      <b-form-checkbox size="lg" id="policy" v-model="acceptPolicy" name="policy" :value="true"
        :unchecked-value="false">
        {{ $t('policy.accept_policy') }}
      </b-form-checkbox>
      <b-form-checkbox size="lg" id="project-policy" v-model="acceptPolicyProject" name="project-policy" :value="true"
        v-if="project?.url_policy != null" :unchecked-value="false">
        <a :href="project?.url_policy" target="_blank" rel="noopener noreferrer">{{ $t('policy.accept_project') }}</a>
      </b-form-checkbox>
      <div slot="modal-footer" class="w-100">

        <div class="d-flex">
          <b-button class="button-fk-on-white" @click="savePolicy()" :disabled="!accepted">{{ $t('policy.accept')
            }}</b-button>
          &nbsp;
          <b-button class="button-fk-on-white" @click="logOut()">{{ $t('policy.no_accept') }}</b-button>
        </div>

      </div>
    </b-modal>

    <Teleport to="#modal">
      <b-overlay :show="isLoading" no-wrap rounded :opacity="0.5" :blur="'120px'" :bg-color="'#333399'">
        <template #overlay>
          <div class="loadwes">
            <img src="@/assets/new_fraktal2.png" alt="">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </template>

      </b-overlay>
    </Teleport>
    <div class="offline" v-if="!isOnline">
      <icon icon="tabler:cloud-x" /> {{ $t('general.offline') }}
    </div>
  </div>
</template>

<script>
import VAPI from './http_common';
import { SERVICE_NAMES, HTTP_STATUS, EKEY } from './app_constants';
import Teleport from 'vue2-teleport';
export default {
  components: {
    Teleport,
  },
  data() {
    return {
      acceptPolicy: false,
      acceptPolicyProject: false,
    }
  },
  computed: {
    accepted() {
      if (this.project.url_policy != null) {
        return this.acceptPolicy && this.acceptPolicyProject
      }
      return this.acceptPolicy
    },
    show() {
      if (this.isLoading) return false
      // Verifica si el estado de la política no está definido
      if (this.policyStatus == null) return false;

      // Verifica si el usuario no está autenticado
      if (!this.isAuth) return false;

      // Verifica si el proyecto no está definido o no tiene un ID
      if (this.project == null || this.project.id == null) return false;

      // Verifica si las fechas necesarias existen antes de continuar
      if (!this.project.date_policy || !this.policyStatus.acceptance_date) return false;

      // Obtén las fechas de las políticas del proyecto y la aceptación del usuario
      const projectDate = new Date(this.project.date_policy);
      const policyAcceptanceDate = new Date(this.policyStatus.acceptance_date);

      // Verifica si la fecha de la política del proyecto es más reciente que la fecha de aceptación
      if (projectDate > policyAcceptanceDate) return true;

      // Verifica si la política actual no es válida
      return !this.policyStatus.is_valid;
    },
    isAuth() {
      return this.$store.getters.isAuthenticated
    },
    currentUser() {
      return this.$store.getters.getCurrentUser
    },
  },
  methods: {
    savePolicy() {
      let data = {
        policy: this.policyStatus.id_policy,
        user: this.currentUser.id,
        project: this.project.id
      }
      VAPI.post(`/${SERVICE_NAMES.USER}/policy`, data).then((response) => {
        if (response.status == HTTP_STATUS.OK) {
          this.$store.dispatch('policyStatusQuery', { data: this.project.id, loader: true })
          this.acceptPolicy = false
          this.acceptPolicyProject = false
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    logOut() {
      this.$store.dispatch('logOut', { redirect: true })
    },
  },
}
</script>

<style lang="scss">
@import './stylessheets/style.scss';

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

#app {
  width: 100%;
  height: 100%;
}

.policy {
  height: 65dvh;
  overflow: auto;
  padding: 10px;
}

.loadwes {
  display: grid;
  justify-items: center;
  align-items: center;

  img {
    height: 100px;
    object-fit: contain;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-top: -50px;

  div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }

    &:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }

    &:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }

    &:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  }
}

.offline {
  position: fixed;
  bottom: 75px;
  right: 0;
  text-align: right;
  width: auto;
  color: $white;
  background-color: $red;
  padding: 5px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
</style>
